/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
  throwError,
} from "rxjs";
import {
  map as __map,
  filter as __filter,
  map,
  tap,
  catchError,
} from "rxjs/operators";

import {
  Associateddata,
  Associateddatas,
  AssociateddataForCreation,
  AssociateddataForUpdate,
  AssociateddataForDelete,
} from "../_models/associateddata";

import { Upload, upload } from './upload';

@Injectable({
  providedIn: "root",
})
class AssociateddataService extends __BaseService {
  fragment: String;
  constructor(config: __Configuration, http: HttpClient, private route: ActivatedRoute) {
    super(config, http);
    this.route.fragment.subscribe((fragment: string) => {
      
      if(fragment)
      {
        this.fragment = fragment;
      }
    });
  }

  /**
   * Returns all associateddatas
   */
  getAllResponse(
    search: any
  ): __Observable<__StrictHttpResponse<Associateddatas>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";

    console.log("getAllResponse", str);
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/associateddata?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Associateddatas>;
      })
    );
  }

  /**
   * Returns all associateddatas
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Associateddatas)
    );
  }

  /**
   * Returns a single associateddata
   * @param associateddataId ID of pet to return
   * @return successful operation
   */
  getAssociateddataByIdResponse(
    associateddataId: number
  ): __Observable<__StrictHttpResponse<Associateddata>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/associateddata/${associateddataId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Associateddata>;
      })
    );
  }
  /**
   * Returns a single associateddata
   * @param associateddataId ID of pet to return
   * @return successful operation
   */
  getAssociateddataById(
    associateddataId: number
  ): __Observable<Associateddata> {
    return this.getAssociateddataByIdResponse(associateddataId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Associateddata)
    );
  }
  
  /**
   * @param associateddataId Number
   * @param file Formdata
   *
   * - `associateddataId`: id of the associateddata
   * - `file`: FormData Object of blob/binary file to upload for associateddata given id
   *
   * @return httpResponse status operation
   *
   */
  postAssociateddataFile(
    associateddataId: number,
    file: FormData
  ): __Observable<Upload> {
    let url = this.rootUrl + `/associateddata/upload/` + associateddataId;

    let body = file;


    return this.http
      .post(url, body, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(upload());



    // let req = new HttpRequest<any>(
    //   "POST",
    //   url,
    //   body
    //   // pas de header pour le formdata
    // );

    
    // return this.http.request<any>(req).pipe(/*
    //   //catchError(this.handleError),
    //   tap((_r) => {
    //     console.log("RESPONSE", _r);
    //   }),
    //   __map((_r) => {
    //     return true;
    //   })
    //   */
    //   upload()
    // );
  }

  /**
   * @param params The `AssociateddataService.UpdateAssociateddataParams` containing the following parameters:
   *
   * - `associateddataname`: associateddataname of the associateddata
   * - `name`: name of the associateddata
   * - `email`: email of the associateddata
   * - `password`: password of the associateddata
   * - `companyId`: Id from the company of the associateddata
   *
   *
   */
  createAssociateddataResponse(
    params: AssociateddataForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/associateddata`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AssociateddataService.UpdateAssociateddataParams` containing the following parameters:
   *
   * - `associateddataId`: ID of associateddata that needs to be updated
   *
   * - `status`: Updated status of the associateddata
   *
   * - `name`: Updated name of the associateddata
   */
  updateAssociateddataResponse(
    params: AssociateddataForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/associateddata/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AssociateddataService.UpdateAssociateddataParams` containing the following parameters:
   *
   * - `id`: ID of associateddata that needs to be updated
   *
   * - `status`: Updated status of the associateddata
   *
   * - `name`: Updated name of the associateddata
   */
  updateAssociateddata(params: AssociateddataForUpdate): __Observable<null> {
    return this.updateAssociateddataResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `AssociateddataService.DeleteAssociateddataParams` containing the following parameters:
   *
   * - `id`: Associateddata id to delete
   *
   * - `api_key`:
   */
  deleteAssociateddataResponse(
    params: AssociateddataForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/associateddata/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AssociateddataService.DeleteAssociateddataParams` containing the following parameters:
   *
   * - `id`: Associateddata id to delete
   *
   * - `api_key`:
   */
  deleteAssociateddata(params: AssociateddataForDelete): __Observable<null> {
    return this.deleteAssociateddataResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "associateddata";
  }

  columnsAssociateddata() {

    var cols = [{
      columnDef: "id",
      header: "Id",
      cell: (element: any) => `${element.id}`,
    },
    /*{
      columnDef: "dataidkind",
      header: "LISTS.DATAKIND",
      cell: (element: any) => `${element.dataidkind}`,
    },*/
    // { columnDef: 'datavalue', header: 'datavalue', cell: (element: any) => `${element.datavalue}` },
    {
      columnDef: "datakind",
      header: "LISTS.DATAKIND",
      cell: (element: any) => `${element.datakind}`,
    },
    {
      columnDef: "datalabel",
      header: "LISTS.DATALABEL",
      cell: (element: any) => `${element.datalabel}`,
    },
    // { columnDef: 'countryname', header: 'country', cell: (element: any) => `${element.countryname}` },
    // { columnDef: 'regionname',     header: 'region',   cell: (element: any) => `${element.regionname}`     },
    // { columnDef: 'cityname', header: 'city', cell: (element: any) => `${element.cityname}` },
    // { columnDef: 'airportname', header: 'airport', cell: (element: any) => `${element.airportname}` },
    // { columnDef: 'hotelname', header: 'hotel', cell: (element: any) => `${element.hotelname}` },
    {
      columnDef: "folderpath",
      header: "LISTS.FOLDERPATH",
      cell: (element: any) => `\\\\data\\Ressources\\lbrdata\\${element.folderpath}`,
      // cell: (element: any) => `\\\\192.168.1.5\\Ressources 2010\\lbrdata\\${element.folderpath}`,
    },
    /*{
      columnDef: "tripendname",
      header: "LISTS.TRIP_END",
      cell: (element: any) => `${element.tripendname}`,
    },*/
];

    // if(this.fragment=="LISTS.TITLES.TRIP")
    // {
      
      // cols.push({
      //   columnDef: "tripendname",
      //   header: "LISTS.TRIP_END",
      //   cell: (element: any) => `${element.tripendname}`,
      // });
    // }

    // if(this.fragment=="LISTS.TITLES.CITY")
    // {
    //   cols.push({
    //     columnDef: "cityname",
    //     header: "LISTS.CITY",
    //     cell: (element: any) => `${element.cityname}`,
    //   });
    // }

    cols.push({
      columnDef: "langageid",
      header: "LISTS.LANGAGE_ID",
      cell: (element: any) => `${element.langageid}`,
    });

    return cols;

    
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError("Unknow error; please try again later.");
  }
}

module AssociateddataService {}

export { AssociateddataService };
