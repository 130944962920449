//for app localisation + provide
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID } from '@angular/core';
registerLocaleData(localeFr);

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatNativeDateModule} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { SuccessDialogComponent } from './_shared/dialogs/success-dialog/success-dialog.component';
import { FileSaverModule } from 'ngx-filesaver';


import { SharedModule } from '@app/_shared/shared.module';
import { StateService } from '@app/_services/state.service';

// used to create fake backend
// import { fakeBackendProvider } from './_helpers';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from './material-module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { LoginComponent } from './login';
import { MenuLayoutComponent } from './_shared/layouts/menu-layout.component';
import { LoginLayoutComponent } from './_shared/layouts/login-layout.component';
import { HeaderComponent } from './_shared/header/header.component';
import { AdminModule } from './admin/admin.module';
import { CommandModule } from './command/command.module';
import { ListModule } from './list/list.module';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { DeleteDialogComponent } from './_shared/dialogs/delete-dialog/delete-dialog.component';
import { PublishDialogComponent } from './_shared/dialogs/publish-dialog/publish-dialog.component';
import { SalesforceDialogComponent } from './_shared/dialogs/salesforce-dialog/salesforce-dialog.component';
import { AddressDialogComponent } from './_shared/dialogs/address-dialog/address-dialog.component';
import { NonameDialogComponent } from './_shared/dialogs/noname-dialog/noname-dialog.component';
import { NocityDialogComponent } from './_shared/dialogs/nocity-dialog/nocity-dialog.component';

import { MAT_DATE_LOCALE } from '@angular/material/core';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient],
            }
        }),
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        AdminModule,
        CommandModule,
        ListModule,
        MaterialModule,
        MatNativeDateModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        SharedModule,
        FlexLayoutModule,
        FileSaverModule
 

    ],
    declarations: [
        AppComponent,
        LoginComponent,
        MenuLayoutComponent,
        LoginLayoutComponent,
        HeaderComponent,
        SuccessDialogComponent,
        DeleteDialogComponent,
        PublishDialogComponent,
        SalesforceDialogComponent,
        AddressDialogComponent,
        NonameDialogComponent,
        NocityDialogComponent,
        // OrderComponent
    ],
    entryComponents: [
        SuccessDialogComponent,
        DeleteDialogComponent,
        PublishDialogComponent,
        SalesforceDialogComponent,
        AddressDialogComponent,
        NonameDialogComponent,
        NocityDialogComponent,
        
      ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        { provide: LOCALE_ID, useValue: "fr-FR" },//localization
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        StateService,

        // provider used to create fake backend
        // fakeBackendProvider
    ],
    exports: [
        MatNativeDateModule, 
        MaterialModule,
        SharedModule
    ],
    bootstrap: [AppComponent],
})

export class AppModule { 
    constructor(overlayContainer: OverlayContainer){
        overlayContainer.getContainerElement().classList.add('angular-material-router-app-theme');
      }
}